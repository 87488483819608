<template>
  <div class="discount">
    <div class="public-margin-bottom">
      <el-button
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="addDiscount"
        >新增比例</el-button
      >
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      height="calc(100vh - 200px)"
      stripe
    >
      <el-table-column prop="DiscNo" label="折扣编号"> </el-table-column>
      <el-table-column prop="DiscName" label="折扣名称"> </el-table-column>
      <el-table-column prop="DiscRatio" label="折扣比例"> </el-table-column>
      <el-table-column label="总部">
        <template slot-scope="scope">
          <div>{{ scope.row.HeadMark | whether }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="onEditDiscount(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="danger"
            @click="onDeleteDiscount(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <EditDialog
      :formData="formData"
      :etitle="etitle"
      :dialogTableVisible="showDialog"
      :callFatherFunction="callFatherFunction"
      @closeDialog="onCloseDialog"
    >
    </EditDialog>
  </div>
</template>

<script>
import setting from "@/api/setting.js";
import EditDialog from "./components/edit-dialog.vue";
export default {
  name: "StoreWebCategory",

  components: { EditDialog },

  directives: {},

  data() {
    return {
      tableData: [],
      formData: {},
      etitle: "",
      showDialog: false,
    };
  },

  mounted() {
    this.initPageData();
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    async initPageData() {
      try {
        let { data } = await setting.getParadisc();
        this.tableData = data;
      } catch (e) {
        console.error(e);
      }
    },

    addDiscount() {
      this.etitle = "添加比例";
      this.formData = {
        DiscName: "",
        DiscRatio: "",
      };
      this.showDialog = true;
    },

    onEditDiscount(event) {
      this.etitle = "修改比例";
      this.formData = event;
      this.showDialog = true;
    },

    async onDeleteDiscount(event) {
      this.$confirm(`是否删除该‘${event.DiscName}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await setting.deleteParadisc({
              discGuid: event.DiscGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch(() => {});
    },

    onCloseDialog() {
      this.initPageData();
    },

    callFatherFunction() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.discount {
  height: 100%;
  padding-top: 20px;
}
</style>
